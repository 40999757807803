import React, { Profiler, useEffect, useState } from 'react';
import { useHistory } from "react-router-dom";

import { Typography, AppBar, Toolbar, Grid, IconButton, MenuItem, Menu, ListItemIcon, Divider, FormControl, FormGroup, FormControlLabel, Checkbox, Dialog, DialogActions, DialogContent, DialogTitle, Button, makeStyles, TextField, InputAdornment, InputLabel, OutlinedInput } from '@material-ui/core';
import { ArrowBack, ImportExport, Favorite as FavouriteIcon, FavoriteBorder as FavouriteBorderIcon, MoreVert, PlaylistAdd, FavoriteBorder, Share, VisibilityOff, WrapText, Fullscreen, FormatSize, Visibility, Add, Edit } from '@material-ui/icons';

import Max from '../util/Max';
import { getPlaylists } from 'lib/library';
import AddToListDialog from './AddToListDialog';
import LocalStorage from 'lib/localstorage';
import ShareDialog from 'components/playlist/ShareDialog';
import FontDialog from './FontDialog';
import TransposeDialog from './TransposeDialog';


const useStyles = makeStyles((theme) => ({
    root: {
      
    },
    title: {
        flexGrow: 1,
    },
    listIcon: {
        minWidth: 32,
    },
	toolbarContainer: {
		width: '100%',
		position: 'fixed',
		//border: '4px solid hotpink',
		boxSizing: 'border-box',
		zIndex: 50,
		padding: theme.spacing(0, 1),
		background: 'rgba(0,0,0,0.6)',
		backdropFilter: 'blur(5px)',
		left: -8,
	},
	toolbar: {
		position: 'relative',
		left: 8,
		minHeight: 50,
		paddingLeft: 500 + theme.spacing(7),
		paddingTop: 16,
		paddingBottom: 4,
		paddingRight: theme.spacing(3),
		display: 'flex',
		justifyContent: 'space-between',
		alignItems: 'center',
		[theme.breakpoints.down('md')]: {
			paddingLeft: theme.spacing(0),
			paddingTop: theme.spacing(1),
			paddingRight: theme.spacing(0),
		},
	}
}));

export default function SongToolbar(props){
	if(!props.song) return null;

    const local = new LocalStorage();

	const classes = useStyles();

	const [anchorEl, setAnchorEl ] = useState(null);

    const open = Boolean(anchorEl);

	const handleMenu = (event) => {
        setAnchorEl(event.currentTarget);
    }

    const handleClose = () => {
        setAnchorEl(null);
    };

    const history = useHistory();

	// add to list
	const [lists, setLists] = useState([]);
    const [addToListPopup, setAddToListPopup] = useState(false);
    const [selectedLists, setSelectedLists] = useState({});
    const [favourite, setFavourite] = useState(false);
	const [shareDialogOpened, setShareDialogOpened] = useState(false);
	const [fontDialogOpened, setFontDialogOpened] = useState(false);
	const [transposeDialogOpened, setTransposeDialogOpened] = useState(false);
	const [songURL, setSongURL] = useState('');

	const handleListSelect = (event) => {
        setSelectedLists({ ...selectedLists, [event.target.name]: event.target.checked });
    };

	// naloži sezname
	useEffect( () => {
		setLists(getPlaylists());
        setFavourite(local.data.favourites.indexOf(props.song.id) !== -1);

	}, [props.song]);

	const handleFavourite = ()=>{
        handleClose();
        let i = local.data.favourites.indexOf(props.song.id);
        if(i == -1){
            local.data.favourites.push(props.song.id);
            setFavourite(true);
        }
        else{
            local.data.favourites.splice(i,1);
            setFavourite(false);
        }
        local.save();
    }

	const handleShare = () => {
		handleClose();
		setSongURL(`${window.location.origin}/song/${props.song.id}`);
		setShareDialogOpened(true);
	}

	const handleBack = () => {
		if(!props.backURL) history.goBack();
		else history.push(props.backURL);
	}

	const handleProposeCorrection = () => {
		handleClose();
		window.open(`https://docs.google.com/forms/d/e/1FAIpQLScTIctf-Tmih-e3nHVhpKIfrJdHNSq15he4cqwEtogrXqDyaQ/viewform?entry.484105704=${props.song.title}`, "_blank");
	}

	return <>
		<div className={classes.toolbarContainer}>
			<Max className={classes.toolbar} style={props.fullWidth ? {paddingLeft: 24} : {}}>
				<div>
					<IconButton onClick={handleBack}><ArrowBack/></IconButton>
				</div>
				<div>
					<IconButton onClick={handleFavourite} color="inherit">
                        {favourite ? <FavouriteIcon /> : <FavouriteBorderIcon /> }
                    </IconButton>
					<IconButton onClick={e => setAddToListPopup(true)}><PlaylistAdd/></IconButton>
					<IconButton onClick={handleMenu}><MoreVert/></IconButton>
				</div>

				<Menu
					id="menu-appbar"
					anchorEl={anchorEl}
					anchorOrigin={{
					vertical: 'top',
					horizontal: 'right',
					}}
					keepMounted
					transformOrigin={{
					vertical: 'top',
					horizontal: 'right',
					}}
					open={open}
					onClose={handleClose}
				>
					<MenuItem onClick={handleFavourite}>
						<ListItemIcon className={classes.listIcon}>
							<FavoriteBorder fontSize="small" />
						</ListItemIcon>
						<Typography variant="inherit">Dodaj med priljubljene</Typography>
					</MenuItem>
					<MenuItem onClick={()=>{handleClose(); setAddToListPopup(true)}}>
						<ListItemIcon className={classes.listIcon}>
							<PlaylistAdd fontSize="small" />
						</ListItemIcon>
						<Typography variant="inherit">Dodaj na seznam</Typography>
					</MenuItem>
					<MenuItem onClick={handleShare}>
						<ListItemIcon className={classes.listIcon}>
							<Share fontSize="small" />
						</ListItemIcon>
						<Typography variant="inherit">Deli</Typography>
					</MenuItem>
					<MenuItem onClick={handleProposeCorrection}>
						<ListItemIcon className={classes.listIcon}>
							<Edit fontSize="small" />
						</ListItemIcon>
						<Typography variant="inherit">Predlagaj popravek</Typography>
					</MenuItem>
					<Divider></Divider>
					<MenuItem onClick={() => {handleClose(), setTransposeDialogOpened(true)}}>
						<ListItemIcon className={classes.listIcon}>
							<ImportExport />
						</ListItemIcon>
						<Typography variant="inherit">Transponiraj</Typography>
					</MenuItem>
					<MenuItem onClick={() => props.setShowChords(!props.showChords)}>
						<ListItemIcon className={classes.listIcon}>
							{props.showChords ? <VisibilityOff fontSize="small" /> : <Visibility fontSize="small" />}
						</ListItemIcon>
						<Typography variant="inherit">{props.showChords ? "Skrij akorde" : "Pokaži akorde"}</Typography>
					</MenuItem>
					<MenuItem onClick={() => props.setLineWrap(!props.lineWrap)}>
						<ListItemIcon className={classes.listIcon}>
							<WrapText fontSize="small" />
						</ListItemIcon>
						<Typography variant="inherit">{props.lineWrap ? "Ne prelomi vrstic" : "Prelomi vrstice"}</Typography>
					</MenuItem>
					<MenuItem onClick={() => {handleClose(), props.toggleFullScreen()}}>
						<ListItemIcon className={classes.listIcon}>
							<Fullscreen fontSize="small" />
						</ListItemIcon>
						<Typography variant="inherit">Projekcija</Typography>
					</MenuItem>
					<MenuItem onClick={() => {handleClose(), setFontDialogOpened(true)}}>
						<Grid container>
							<ListItemIcon className={classes.listIcon}>
								<FormatSize fontSize="small" />
							</ListItemIcon>
							<Typography style={{flexGrow: 1}} variant="inherit">Pisava</Typography>
						</Grid>
					</MenuItem>
				</Menu>
			</Max>

			<AddToListDialog song={props.song} open={addToListPopup} onClose={() => setAddToListPopup(false)}/>
		</div>
		<ShareDialog title="Deli pesem" text="Kopiraj povezavo do pesmi" url={songURL} opened={shareDialogOpened} close={() => setShareDialogOpened(false)}/>
		<FontDialog open={fontDialogOpened} onClose={() => setFontDialogOpened(false)} 
			fontFamily={props.fontFamily} setFontFamily={props.setFontFamily} 
			fontSize={props.fontSize} setFontSize={props.setFontSize}
		/>
		<TransposeDialog open={transposeDialogOpened} onClose={() => setTransposeDialogOpened(false)} originalKey={props.originalKey} transposeSemitones={props.transposeSemitones} setTransposeSemitones={props.setTransposeSemitones} />
	</>
}