import React from "react"
import { makeStyles, Typography } from "@material-ui/core";

import Max from 'components/util/Max.js';
import ScrollArea from 'components/util/ScrollArea.js';
import SongView from "components/song/SongView";


const useStyles = makeStyles( theme => ({
	section: {
		minWidth: '100%',
		height: '100%',
		paddingTop: 56,
		scrollSnapAlign: 'start',
	},
	container: {
		boxSizing: 'border-box',
		width: '100%',
		minWidth: '100%',
		height: '100%',
		maxHeight: '100%',
		padding: theme.spacing(2, 3),
		position: 'relative',
		[theme.breakpoints.down('md')]: {
            padding: 0,
        }
	},
	songArea: {
		height: '100%',
		paddingLeft: 500 + theme.spacing(4),
		[theme.breakpoints.down('md')]: {
			paddingLeft: 0,
        }
	},
	songAreaPlaceholder: {
		height: '100%',
		width: '100%',
		display: 'flex',
		flexDirection: 'column',
		alignItems: 'center',
		justifyContent: 'center',
		color: theme.palette.grey[900],
	},
	tmpTitle: {
		position: 'relative',
		paddingLeft: theme.spacing(2),
        paddingRight: theme.spacing(2),
		[theme.breakpoints.down(1600)]: {
			left: 0,
		},
		[theme.breakpoints.down('md')]: {
			marginLeft: theme.spacing(2),
			marginTop: theme.spacing(2),
		}
	},
}));

export function SongPage(props){
	const classes = useStyles();

	let songView;
	if(props.song){
		if(props.showFull) songView = <SongView 
			song={props.song} showChords={props.showChords} lineWrap={props.lineWrap} 
			fontSize={props.fontSize} fontFamily={props.fontFamily} 
			transposeSemitones={props.transposeSemitones} 
			fullScreen={props.fullScreen}
		/>;
		else songView = <Typography variant="h1" className={classes.tmpTitle}>{props.song?.title}</Typography>
	}
	else{
		songView = <div className={classes.songAreaPlaceholder}><h1>Jubilate</h1><p>Izberi pesem</p></div>
	}

	return <ScrollArea className={classes.section}>
		{
			<Max style={{height: '100%'}} compensateScrollbar>
				<div className={classes.container}>
					<div className={classes.songArea} style={props.fullWidth ? {paddingLeft: 0} : {}}>
						{ songView }
					</div>
				</div>
			</Max>
		}
	</ScrollArea>
}