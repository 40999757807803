import React, {useState, useEffect} from 'react';
import { matchPath, useLocation, Link } from "react-router-dom";
import { ButtonBase } from '@material-ui/core';
import {List as ListIcon, Home as HomeIcon, Person as PersonIcon} from '@material-ui/icons';
import { makeStyles } from '@material-ui/core/styles';
import clsx from 'clsx';

import SearchBar from 'components/search/SearchBar';
import Max from 'components/util/Max';

const useStyles = makeStyles((theme) => ({
    root: {
        width: '100%',
        zIndex: theme.zIndex.appBar,
        background: theme.palette.primary.main,
        boxShadow: '0 0 10px 0 rgba(0, 0, 0, 0.5)',
        position: 'fixed',
        top: 'auto',
        bottom: 0,
        [theme.breakpoints.up('lg')]: {
            background: theme.palette.grey[900],
            top: 0,
            bottom: 'auto',
        }
    },
    row: {
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'space-between',    
        boxSizing: 'border-box',
        [theme.breakpoints.up('lg')]: {
            background: theme.palette.grey[900],
            top: 0,
            bottom: 'auto',
            padding: theme.spacing(0, 2),
        },
    },
    search: {
        display: "none",
        [theme.breakpoints.up('lg')]: {
            display: "block",
            width: '100%',
        }
    },
    searchBar: {
        width: 300,
    },
    title: {
        width: '100%',
        textAlign: "center",
        fontSize: "2em",
        fontWeight: "bold",
        textDecoration: "none",
        color: theme.palette.text.primary,
        display: "none",
        [theme.breakpoints.up('lg')]: {
            display: "block",
        }
    },
    buttons: {
        height: '56px',
        display: 'flex',
        maxWidth: '500px',
        margin: '0 auto',
        width: '100%',
        [theme.breakpoints.up('lg')]: {
            margin: 0,
            height: '70px',
            justifyContent: 'flex-end',
        },
        '&>*': {
            width: '100%',
            height: '100%',
            [theme.breakpoints.up('lg')]: {
                width: '70px',
            },
        }
    },
    button: {
        color: theme.palette.primary.dark,
        transition: 'color 0.3s',
        [theme.breakpoints.up('lg')]: {
            color: theme.palette.text.primary,
        },
    },
    buttonSelected: {
        color: '#fff',
        transition: 'color 0.3s',
        [theme.breakpoints.up('lg')]: {
            color: theme.palette.secondary.main,
        },
    }
}));
  
export default function Navigation(props){
    
    let currentRoute = "";
    const currentPath = useLocation().pathname;
    if(matchPath(currentPath, {path: "/"}))currentRoute='/';
    if(matchPath(currentPath, {path: "/categories"}))currentRoute='/categories';
    if(matchPath(currentPath, {path: "/category"}))currentRoute='/categories';
    if(matchPath(currentPath, {path: "/profile"}))currentRoute='/profile';
    
    const classes = useStyles();
    const [value, setValue] = useState(currentRoute);

    useEffect(() => {
        setValue(currentRoute);
    }, [currentRoute])

    return (
        <div className={clsx(classes.root, props.className)}>
            <Max className={classes.row}>
                <div className={classes.search}>
                    <SearchBar className={classes.searchBar}></SearchBar>
                </div>
                <Link className={classes.title} to="/">Jubilate</Link>
                <div className={classes.buttons}>
                    <ButtonBase component={Link} to='/categories'><ListIcon className={currentPath === '/categories' ? classes.buttonSelected : classes.button}/></ButtonBase>
                    <ButtonBase component={Link} to='/'><HomeIcon className={currentPath === '/' ? classes.buttonSelected : classes.button}/></ButtonBase>
                    <ButtonBase component={Link} to='/profile'><PersonIcon className={currentPath === '/profile' ? classes.buttonSelected : classes.button}/></ButtonBase>
                </div>
            </Max>
        </div>
    );
}