import React, { Profiler, useEffect, useState } from 'react';

import { Typography, Button, ButtonGroup, Slider, Select, AppBar, Toolbar, Grid, IconButton, MenuItem, Menu, ListItemIcon, Divider, FormControl, FormGroup, FormControlLabel, Checkbox, Dialog, DialogActions, DialogContent, DialogTitle, makeStyles, TextField, InputAdornment, InputLabel, OutlinedInput } from '@material-ui/core';
import { ArrowBack, Favorite, MoreVert, PlaylistAdd, FavoriteBorder, Share, VisibilityOff, WrapText, Fullscreen, FormatSize, Visibility, Add, Label } from '@material-ui/icons';
import { scale, chordId } from 'lib/library';

export default function TransposeDialog(props){

	let originalKey = chordId(props.originalKey)[0];
	const valueLabelFormat = (value) => {
		if(originalKey !== -1) return `${scale[(value + originalKey + 12) % 12]}`;
		return `${value}`;
	}

	const marks = [];
	for(let i=-6; i<=6; i++){
		marks.push({value: i, label: (i > 0 ? "+" : "") + i});
	}

	return <Dialog open={props.open} onClose={props.onClose} fullWidth={true} maxWidth="xs">
		<DialogTitle id="form-dialog-title">Transponiraj</DialogTitle>
		<DialogContent dividers>
			<Typography gutterBottom>
				Izberi število poltonov:
			</Typography>
			<Slider
				value={props.transposeSemitones}
				min={-6}
				step={1}
				max={6}
				track={false}
				marks={marks}
				getAriaValueText={valueLabelFormat}
				valueLabelFormat={valueLabelFormat}
				onChange={(e,x) => props.setTransposeSemitones(x)}
				valueLabelDisplay="auto"
			/>
		</DialogContent>
		<DialogActions>
			<Button onClick={() => props.onClose()} color="primary">
					Zapri
			</Button>
		</DialogActions>
	</Dialog>
}