import React, {useEffect, useState} from 'react';
import { getSongs } from 'lib/library.js';
import { makeStyles } from '@material-ui/core/styles';
import { Button, Card, Typography } from '@material-ui/core';
import { useHistory } from 'react-router-dom';

const useStyles = makeStyles((theme) => ({
	card: {
		position: 'relative',
		overflow: 'hidden',
		height: 400,
		padding: theme.spacing(2),
		'@media (max-width: 600px)': {
			height: 500
		}
	},
	wheelContainer: {
		position: 'absolute',
		width: '100%',
		height: '100%',
		display: 'flex',
		alignItems: 'center',
		justifyContent: 'space-around',
		'@media (max-width: 600px)': {
			left: 0,
			flexDirection: 'column',
			justifyContent: 'center'
		},
		'@media (max-width: 600px)': {
			transform: 'rotate(90deg)'
		}
	},
	marker: {
		position: 'absolute',
		width: 40,
		height: 20,
		clipPath: 'polygon(0 0, 100% 50%, 0 100%)',
		background: "#444",
		marginLeft: '-60vw',
		'@media (min-width: 1500px)': {
			marginLeft: -750
		},
		'@media (max-width: 1000px)': {
			marginLeft: '-30vw'
		}
	},
	wheel: {
		position: 'relative',
		left: '50vw',
		width: '150vw',
		height: '150vw',
		minWidth: '150vw',
		minHeight: '150vw',
		borderRadius: "50%",
		overflow: 'hidden',
		transitionProperty: 'transform',
		transitionDuration: '4s',
		transitionTimingFunction: 'cubic-bezier(0.42,-0.22, 0.19, 1.01)',
		'@media (min-width: 1500px)': {
			width: '2250px',
			height: '2250px',
			minWidth: '2250px',
			minHeight: '2250px',
			left: 800
		},
		'@media (max-width: 1000px)': {
			left: '70vw'
		},
		'@media (max-width: 1000px)': {
			width: '1000px',
			height: '1000px',
			minWidth: '1000px',
			minHeight: '1000px'
		}
	},
	rotated: {
		transform: 'rotate(540deg)',
	},
	item: {
		position: 'absolute',
		top: '47.25%',
		left: '50%',
		transformOrigin: 'center left',
		paddingLeft: '10%',
		paddingRight: '4%',
		height: '6.5%',
		backgroundColor: 'blue',
		boxSizing: 'border-box',
		width: '51%',
		display: 'flex',
		alignItems: 'center',
		justifyContent: 'flex-end',
		clipPath: 'polygon(100% 0, 0 50%, 100% 100%)',
	},
	title: {
		maxWidth: "100%",
		whiteSpace: 'nowrap',
		overflow: 'hidden',
		textOverflow: 'ellipsis',
		transform: 'rotate(180deg)',
		fontSize: '2vw',
		'@media (max-width: 600px)': {
			fontSize: '1rem',
		},
		'@media (min-width: 1500px)': {
			fontSize: '2rem',
		}
	},
	odd: {
		backgroundColor: theme.palette.extra.main,
	},
	even: {
		backgroundColor: theme.palette.extra.dark,
	},
}));

export default function SongWheel(){
	const classes = useStyles();
	const history = useHistory();
	let songs = [...getSongs()];
	let [selection, setSelection] = useState([]);
	let [rotated, setRotated] = useState(false);
	const n_items = 50;

	useEffect(() => {
		let s = [];
		for(let i = 0; i < n_items; i++){
			let index = Math.floor(Math.random() * songs.length);
			s.push(songs[index]);
			songs.splice(index, 1);
		}
		setSelection(s);
	}, []);

	let timeout = null;

	function spin(){
		setRotated(true);
		timeout = setTimeout(() => history.push(`/song/${selection[0].id}`), 4000);
	}

	useEffect(() => {
		return () => {
			if(timeout) clearTimeout(timeout);
		}	
	}, []);
	


	return <Card className={classes.card}>
			<div className={classes.wheelContainer}>
				<div className={classes.marker}></div>
				<div className={classes.wheel + " " + (rotated ? classes.rotated : "")}>
					{selection.map((song, i) => 
					<div key={i} className={classes.item + " " + (i%2 ? classes.odd : classes.even)} style={{transform: `rotate(${i*360/n_items}deg)`}}>
						<div className={classes.title}>{song.title}</div>
					</div>
					)}
				</div>
			</div>
			<Typography variant="h3" style={{fontSize: "3em", fontWeight: 300}}>Kolo pesmi</Typography>
			<p>Izberi naključno pesem</p>
			<br/>
			<br/>
			<div>
				<Button color="secondary" variant="contained" size='large' onClick={spin}>Zavrti</Button>
			</div>
		</Card>
};
