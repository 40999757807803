import React from "react";
import clsx from "clsx";
import { IconButton, makeStyles, Paper, Typography } from "@material-ui/core";
import SongList from "components/song/SongList";
import { MoreVert } from "@material-ui/icons";
import MylistMenu from "components/playlist/MylistMenu";
import PlaylistMenu from "components/playlist/PlaylistMenu";

const useStyles = makeStyles((theme) => ({
	songListCardContainer: {
		borderRadius: theme.spacing(3),
		position: 'fixed',
		width: 500,
		maxHeight: 'calc(100% - 100px)',
		height: '100%',
		overflow: 'hidden',
		zIndex: 100,
		left: `calc( 50% - 800px )`,
		marginTop: theme.spacing(2),
		marginLeft: theme.spacing(3),
		[theme.breakpoints.down(1600)]: {
			left: 0,
		},
		[theme.breakpoints.down('md')]: {
            width: '100%',
			borderRadius: 0,
			maxHeight: 'calc(100% - 56px)',
			left: 0,
			margin: 0,
        }
	},
	songListCard: {
		width: '100%',
		maxHeight: '100%',
		height: '100%',
		display: 'flex',
		flexDirection: 'column',
		[theme.breakpoints.down('md')]: {
            background: theme.palette.background.default,
        }
	},
	songListContainer: {
		maxHeight: '100%',
		width: '100%',
		overflowY: 'scroll',
	},
	header: {
		background: theme.palette.primary.main,
		minHeight: 80,
		padding: theme.spacing(2),
		paddingRight: theme.spacing(1),
		width: '100%',
		display: 'flex',
		justifyContent: 'space-between',
		alignItems: 'center',
	},
	subtitle: {
		margin: 0,
		minHeight: 17,
		display: 'block',
		color: theme.palette.primary.light,
	},
	title: {
		margin: 0,
	},
}));

export function SongListSidebar(props){
	const classes = useStyles();

	if(!props.list) return null;

	return <div className={clsx(classes.songListCardContainer, props.className)}>
		<Paper className={classes.songListCard}>
			<div className={classes.header}>
				<div>
					<Typography variant="button" className={classes.subtitle}>{props.list.subtitle}</Typography>
					<Typography variant="h3" className={classes.title}>{props.list.title}</Typography>
				</div>
				{ props.list.type === "mylist" && <MylistMenu list={props.list} handleRename={props.handleRename}/> }
				{ props.list.type === "playlist" && <PlaylistMenu list={props.list} handleRename={props.handleRename}/> }
			</div>
			<div className={classes.songListContainer}>
				<SongList songs={props.list.songs} activeSong={props.songInListIndex} linkState={{type: props.list.type, key: props.list.key}} onSort={props.onSort} onRemove={props.onRemove}></SongList>  
			</div>
		</Paper>
	</div>
}