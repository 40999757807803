import Fuse from 'fuse.js';
import React from 'react';

import { getSongs } from './library';

const songs = getSongs()

// remove chords in {} from lyrics
for (let song of songs) {
    if (song.lyrics){
        song.lyrics_no_chords = song.lyrics.replace(/\{.*?\}/g, '').replace(/\n/g, ' ');
    }
};

const options = {
    includeScore: true,
    ignoreLocation: true,
    ignoreFieldNorm: true,
    includeMatches: true,
    threshold: 0.5,
    keys: ['title', 'lyrics_no_chords', 'categories', 'author', 'tags', 'page']
}

const fuse = new Fuse(songs, options);

export const search = (query) => {
	const result = fuse.search(query);
	let filtered_results = result.slice(0, 20)
    for(let r of filtered_results){
        if(r.matches && r.matches.length > 0){
            r.match_text = r.matches[0].value;
            r.match_highlight = r.matches[0].indices.sort((a,b) => -(a[1] - a[0]) + (b[1] - b[0]))[0];
        }
        r.index = r.refIndex;
    }
    filtered_results = filtered_results.map(r => ({...r, ...r.item}))
    console.log(filtered_results);
    return filtered_results;
}

export function MatchHighlight(props){
    let matches = props.matches;
    if(!matches || matches.length == 0) return null;

    let match_text = matches[0].value;
    let match_highlight = matches[0].indices.sort((a,b) => -(a[1] - a[0]) + (b[1] - b[0]))[0];


    let text_before = match_text.substring(Math.max(0, match_highlight[0] - 50), match_highlight[0]); 
    let text_highlight = match_text.substring(match_highlight[0], match_highlight[1]);
    let text_after = match_text.substring(match_highlight[1]);

    return <span>
        {text_before}
        <b style={{color: "#eee"}}>{text_highlight}</b>
        {text_after}
    </span>
}