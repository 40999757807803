import React from 'react';
import clsx from 'clsx';
import { makeStyles, darken } from '@material-ui/core';
import { Link } from 'react-router-dom';
import { SortableElement, SortableHandle } from 'react-sortable-hoc';
import { DragHandle as DragIndicator, RemoveCircle } from '@material-ui/icons';
import { MatchHighlight } from 'lib/search';

const useStyles = makeStyles((theme) => ({
    container: {
        minWidth: 0
    },
    item: {
        minHeight: "56px",
        display: 'flex',
        maxWidth: '100%',
        overflow: 'hidden',
        textOverflow: 'ellipsis',
        alignItems: 'center',
        transitionProperty: 'background',
        transitionDuration: '0.3s',
        background: 'inherit',
        margin: theme.spacing('auto', 0, 'auto', 1),
        borderRadius: theme.spacing(2),
        color: theme.palette.text.primary,
        textDecoration: 'none',
        "&::before": {
            content: "''",
            minWidth: 40,
            minHeight: 40,
            borderRadius: 20,
            backgroundColor: theme.palette.extra.main,
            backgroundImage: `url("data:image/svg+xml,%3Csvg xmlns='http://www.w3.org/2000/svg'%3E%3Cpath d='M12 3v10.55c-.59-.34-1.27-.55-2-.55-2.21 0-4 1.79-4 4s1.79 4 4 4 4-1.79 4-4V7h4V3h-6z' fill='${encodeURIComponent(theme.palette.extra.dark)}' /%3E%3C/svg%3E")`,
            backgroundPosition: '8px 8px',
            backgroundRepeat: 'no-repeat',
            display: 'block',
            marginRight: theme.spacing(2),
            marginLeft: theme.spacing(1),
        },
        "&:hover": {
            backgroundColor: theme.palette.grey[900],
            [theme.breakpoints.down('md')]: {
                background: 'none',
            },
        },
        "&$itemActive": {
            composes: '$item',
            backgroundColor: theme.palette.extra.dark,
            "&:hover": {
                backgroundColor: darken(theme.palette.extra.dark, 0.1),
            }
        }
    },
    itemActive: {},
    titleContainer: {
        overflow: 'hidden',
        paddingRight: theme.spacing(2),
        width: '100%',
    },
    title: {
        fontSize: '1.1rem',
        whiteSpace: 'nowrap',
        textOverflow: 'ellipsis',
        overflow: 'hidden',
    },
    author: {
        color: theme.palette.text.secondary,
        whiteSpace: 'nowrap',
        textOverflow: 'ellipsis',
        overflow: 'hidden',
    },
	dragHandle: {
        padding: '18px 8px 14px',
    }
}));

const DragHandle = SortableHandle(() => {
    const classes = useStyles();

    return <div className={classes.dragHandle}><DragIndicator/></div>
});

const RemoveButton = (props) => {
	const classes = useStyles();

    return <div className={classes.dragHandle} onClick={e => { e.preventDefault(), props.removeHandle()}}><RemoveCircle/></div>;
}
function SongCardBase({song, i, linkState, active, sortable, removeHandle}){
	const classes = useStyles();

	return <div className={classes.container}>
        <Link to={{pathname: `/song/${song.id}`, state: {...linkState, i}}} className={clsx(classes.item, active ? classes.itemActive : null)}>
            <div className={classes.titleContainer}>
                <div className={classes.title}>{song.title}</div>
                <div className={classes.author}>{song.matches ? <MatchHighlight matches={song.matches} /> : song.author}</div>
            </div>
            {sortable && <><RemoveButton removeHandle={removeHandle}/><DragHandle/></>}
        </Link>
    </div>
} 

const SongCardSortable = SortableElement(props => <SongCardBase {...props} sortable/>);

export default function SongCard(props){
	if(props.sortable) return <SongCardSortable {...props}/>
	else return <SongCardBase {...props} />
}