import React, {forwardRef} from 'react';
import { makeStyles } from '@material-ui/core/styles';
import clsx from 'clsx';

const useStyles = makeStyles((theme) => ({
    root: {
        position: 'relative',
        width: '100%',
        maxWidth: '100%',
        height: '100%',
        overflowY: 'scroll',
        overflowX: 'hidden',
        boxSizing: 'border-box',
    },
}));

const ScrollArea = forwardRef((props, ref) => {
	const classes = useStyles();

    return (
		<div className={clsx(classes.root, props.className)} ref={ref}>
			{props.children}
		</div>
    );
});

export default ScrollArea;