import React from 'react';
import Typography from '@material-ui/core/Typography';

import Grid from 'components/util/Grid';
import CategoryCard from 'components/category/CategoryCard';

import { getGroups, getSongs } from 'lib/library';

const groups = getGroups();
const songs = getSongs();

export default function CategoryList(props){
    return (
        <>
            {groups.map( (group) => (
                <div key={group.title} >
                    {group.title && <Typography variant="h2">{group.title}</Typography>}
                    <Grid>
                        {group.categories?.map((category) => (
                            <CategoryCard key={category.id} category={category}></CategoryCard>
                        ))}
                    </Grid>
                </div>
            ))}
            <Typography variant="h2">Vse pesmi</Typography>
            <Grid>
                <CategoryCard category={{id: "all", title: "Vse pesmi", count: songs.length}}></CategoryCard>
            </Grid>
        </>
    );
}