import React from "react";
import { Box, Button, Card, makeStyles, Typography } from "@material-ui/core";
import { PlaylistPlay, Favorite, Add } from "@material-ui/icons";
import { Link } from "react-router-dom";

const useStyles = makeStyles((theme) => ({
	root: {
		padding: theme.spacing(2),
		whiteSpace: "nowrap",
		textDecoration: "none",
		display: 'flex',
		alignItems: 'center',
	},
	title: {
		fontWeight: "500",
		fontSize: "1.4em",
		lineHeight: "1.2em",
		[theme.breakpoints.down('md')]: {
			fontSize: "1.2em",
		},
	},
	text: {
		paddingLeft: theme.spacing(1),
		overflow: 'hidden',
	}
}));

export default function PlaylistCard(props){
	const classes = useStyles();
	let playlist = props.playlist;
	if(!playlist){
		playlist = {id: 0, title: "Placeholder", count: 67, type: "playlist"};
	}

	return <Card component={playlist.type == "new" ? Button : Link} to={playlist.type === 'favourite' ? `/favourites` : `/mylist/${playlist.id}`} className={classes.root} style={{background: playlist.type == "new" && 'none' }} onClick={props.onClick}>
		{ playlist.type == "favourite" ? <Favorite color='primary' fontSize='large' />
		: playlist.type == "new" ? <Add fontSize='large' /> 
		: <PlaylistPlay color='secondary' fontSize='large'/> 
		}
		<div className={classes.text}>
			<Box className={classes.title} component="div" textOverflow="ellipsis" overflow="hidden" >
				{playlist.title}
			</Box>
			{playlist.count !== undefined && <Typography>{playlist.count} pesmi</Typography> }
		</div>
	</Card>
}