import React, {useState, useEffect} from "react";

export default function AdBanner(){
	const [banners, setBanners] = useState([]);

	useEffect(() => {
		try{
			(async () => {
				let res = await fetch("https://gradiva.donbosko.si/jubilate-pesmarica.json");
				let obj = await res.json();
				if(obj.banners) setBanners(obj.banners);
			})();
		}
		catch(e){
		}
	}, []);

	return <SlideShow banners={banners}/>
}

function SlideShow(props){
	if(!props.banners || props.banners.length == 0){
		return null;
	}

	const [slide, setSlide] = useState(0);


	useEffect(() => {
		const interval = setInterval(() => {
			nextSlide();
		}, 5000);
		return () => clearInterval(interval);
	}, []);

	function nextSlide(){
		setSlide(s => (s+1)%props.banners.length);
	}

	return <>
		<div style={{overflow: "hidden", maxWidth: 640, margin: "20px auto 0"}}>
			<div className="oglasiContainer" style={{whiteSpace: "nowrap", lineHeight:"0", position: "relative", left: slide*-100+"%", transition: "left 0.3s"}}>
				{props.banners.map((banner, i) => 
					<a key={i} href={banner.target}><img className="BlockImage" src={banner.src} alt="" style={{width: "100%", display: "inline-block", borderRadius: 12}}></img></a>
				)}
			</div>
		</div>
	</>	
}