import React, { useEffect, useRef, useState} from 'react';
import { Link } from "react-router-dom";
import { Typography, AppBar, Toolbar, IconButton, List, ListItem, ListItemText, ListItemAvatar, Avatar, Button, TextField, Dialog, DialogActions, DialogContent, DialogContentText, DialogTitle } from '@material-ui/core';
import { Settings as MenuIcon, Favorite as FavouriteIcon, MusicNote as NoteIcon, Add as PlusIcon } from '@material-ui/icons';
import { makeStyles } from '@material-ui/core/styles';

import Max from 'components/util/Max.js';
import ScrollArea from 'components/util/ScrollArea';

import {toB64, fromB64} from 'lib/base64';
import LocalStorage from 'lib/localstorage';
import Grid from 'components/util/Grid';
import PlaylistCard from 'components/playlist/PlaylistCard';
import { addPlaylist, getPlaylistsFull, getFavouritesList } from 'lib/library';

  const useStyles = makeStyles((theme) => ({
    root: {
      width: '100%',
      '& > *': {
          paddingTop: 0,
          paddingBottom: 0,
          minHeight: "56px",
      }
    },
    titleRow: {
        margin: theme.spacing(0, 2),
        [theme.breakpoints.up('lg')]: {
            margin: theme.spacing(0, 4),
        },
    },
    container: {
        width: "100%",
        padding: theme.spacing(0, 2, 2),
        boxSizing: "border-box",
        [theme.breakpoints.up('lg')]: {
            padding: theme.spacing(0, 4, 4),
        },
    },
    favouritesAvatar: {
        color: theme.palette.primary.dark,
        backgroundColor: theme.palette.primary.main,
    },

    listAvatar: {
        color: theme.palette.secondary.dark,
        backgroundColor: theme.palette.secondary.main,
    },

    title: {
        flexGrow: 1,
    }
  }));
  
export default function Profile(props){
    const classes = useStyles();
    const local = new LocalStorage();

    const [lists, setLists] = React.useState([]);
    const [newListPopupOpened, setNewListPopupOpened] = React.useState(false);
    const [newListInput, setNewListInput] = useState("");
    const [favourites, setFavourites] = React.useState({});

    useEffect(()=>{
        setLists(getPlaylistsFull());
        setFavourites(getFavouritesList());
    }, [])
    
    const handleNewList = () => {
        if(newListInput == "")return;
        addPlaylist(newListInput)
        setLists(getPlaylistsFull());
        setNewListPopupOpened(false);
        setNewListInput("");
    }
    return (
        <>
            <ScrollArea className={classes.main} >
		    <Max compensateScrollbar>
            <div className={classes.titleRow}>
                <Typography variant="h1">Moji seznami</Typography>
            </div>
            <div className={classes.container}>
                <Grid>
                    <PlaylistCard playlist={favourites}></PlaylistCard>
                    { lists.map( (list, i) => 
                        <PlaylistCard key={i} playlist={list} />    
                    )}
                    <PlaylistCard playlist={{title: "Nov seznam", type: "new"}} onClick={()=>setNewListPopupOpened(true)}></PlaylistCard>
                </Grid>
            </div>
            <Dialog open={newListPopupOpened} onClose={()=>setNewListPopupOpened(false)} aria-labelledby="form-dialog-title">
                <DialogTitle id="form-dialog-title">Nov seznam</DialogTitle>
                <DialogContent>
                    <DialogContentText>
                        Izberi ime seznama, na katerega lahko shraniš pesmi in jih deliš z ostalimi.
                    </DialogContentText>
                    <TextField
                        autoFocus
                        margin="dense"
                        id="name"
                        label="Ime seznama"
                        type="text"
                        fullWidth
                        onChange={e=>setNewListInput(e.target.value)}
                    />
                    </DialogContent>
                    <DialogActions>
                    <Button onClick={()=>setNewListPopupOpened(false)} color="primary">
                        Prekliči
                    </Button>
                    <Button onClick={handleNewList} color="primary">
                        Ustvari seznam
                    </Button>
                </DialogActions>
            </Dialog>
            </Max>
            </ScrollArea>
        </>
    );
}