import React, { useState, useRef } from 'react';
import { 
	IconButton, 
	Typography, 
	Menu, 
	MenuItem, 
	ListItemIcon, 
	Button, 
	TextField, 
	Dialog, 
	DialogActions, 
	DialogContent, 
	DialogContentText, 
	DialogTitle,
	InputAdornment,
	OutlinedInput,
	makeStyles,
} from '@material-ui/core';
import { Add, Delete, Edit, FileCopy, MoreVert, Share } from '@material-ui/icons';


export default function ShareDialog(props) {
	const inputRef = useRef(null);

	const copyToClipboard = () => {
		navigator.clipboard.writeText(inputRef.current.value);
	}

	return <>
		<Dialog open={props.opened} onClose={props.close} aria-labelledby="form-dialog-title">
			<DialogTitle id="form-dialog-title">{props.title}</DialogTitle>
			<DialogContent>
				<DialogContentText>{props.text}</DialogContentText>
				<OutlinedInput
					autoFocus
					margin="dense"
					id="name"
					type="text"
					fullWidth
					readOnly={true}
					value={props.url}
					inputRef={inputRef}
					endAdornment={
						<InputAdornment position="end">
						  <IconButton
							aria-label="toggle password visibility"
							onClick={copyToClipboard}
							edge="end"
						  >
							<FileCopy />
						  </IconButton>
						</InputAdornment>
					  }
				/>
				</DialogContent>
				<DialogActions>
				<Button onClick={props.close} color="primary">
					Zapri
				</Button>
			</DialogActions>
		</Dialog>
	</>
}