import { createMuiTheme } from '@material-ui/core/styles';

const theme = createMuiTheme({
    palette: {
        type: 'dark',
        background: {
            default: "#000",
            paper: "#292929",
        },
        primary: {
            dark: "#9A1D23",
            main: "#B65A4D",
            light: "#DFBAB0",
        },
        secondary: {
            dark: "#E39858",
            main: "#EBB587",
            light: "#F5D5B8",
        },
        extra: {
            dark: "#7fa0a3",
            main: "#a5b9bc",
            light: "#cad4d6",
        }
    },
    overrides: {
        MuiBottomNavigation: {
            root: {
            },
        },
        MuiBottomNavigationAction: {
            root: {
                color: "#C41F38",
                "&$selected": {
                    color: "white"
                }
            }
        },
        MuiToolbar: {
            regular: {
                '@media (min-width: 0px) and (orientation: landscape)': {
                    minHeight: 56 // nočem, da se app bar zmanjša, ko se odpre tipkovnica
                },
                '@media (min-width: 600px)': {
                    minHeight: 56 // nočem, da se app bar poveča ne desktopu
                }
            }
        },
        MuiPaper: {
            rounded: {
                borderRadius: 8,
            }
        },
        MuiButton: {
            endIcon: {
                marginTop: -1.5, // sicer tekst in ikona nista vertikalno poravnana
            }
        }
    },
});

theme.typography.h1 = {
    fontWeight: "700",
    fontSize: "4em",
    margin: theme.spacing(4, 0, 3),
    lineHeight: "0.9em",
    [theme.breakpoints.down('md')]: {
        fontSize: "2em",
        lineHeight: "0.9em",
    }
};

theme.typography.h2 = {
    fontWeight: "300",
    fontSize: "3em",
    margin: theme.spacing(4, 0, 3),
    lineHeight: "0.8em",
    [theme.breakpoints.down('md')]: {
        fontSize: "1.75em",
        lineHeight: "0.9em",
    }
};

theme.typography.h3 = {
    fontWeight: "500",
    fontSize: "2em",
    margin: theme.spacing(2, 0, 1),
    lineHeight: "0.9em",
    [theme.breakpoints.down('md')]: {
        fontSize: "2em",
        lineHeight: "0.9em",
    }
};

theme.typography.h4 = {
    fontWeight: "500",
    fontSize: "1.5em",
    margin: theme.spacing(2, 0, 1),
    lineHeight: "0.4em",
    [theme.breakpoints.down('md')]: {
        fontSize: "2em",
        lineHeight: "0.9em",
    }
};

theme.typography.body1 = {
    fontWeight: "100",
}

export default theme;