import React from 'react';
import useScrollTrigger from '@material-ui/core/useScrollTrigger';
import Slide from '@material-ui/core/Slide';

export default function HideOnScroll(props) {
    let trigger = useScrollTrigger({ target: props.window });

    if(props.enabled === false) trigger = false;

    return (
        <Slide appear={false} direction="down" in={!trigger}>
        {props.children}
        </Slide>
    );
}