import React from 'react';
import { makeStyles } from '@material-ui/core/styles';


const useStyles = makeStyles((theme) => ({
    grid: {
        maxWidth: "100%",
        width: "100%",
        display: "grid",
        gridGap: theme.spacing(4),
        gridTemplateColumns: "repeat(6, 1fr)",
        [theme.breakpoints.down('lg')]: {
            gridTemplateColumns: "repeat(6, 1fr)",
        },
        [theme.breakpoints.down('md')]: {
            gridTemplateColumns: "repeat(3, 1fr)",
            gridGap: theme.spacing(2),
        },
        [theme.breakpoints.down('sm')]: {
            gridTemplateColumns: "repeat(2, 1fr)",
        },
    },
}));


export default function Grid(props){
	const classes = useStyles();
	return <div className={classes.grid}>{props.children}</div>
}