import React, { useRef, useState } from 'react';
import { useHistory } from 'react-router-dom';
import { makeStyles } from '@material-ui/core/styles';
import { Paper, InputBase, IconButton } from '@material-ui/core';
import { Search as SearchIcon } from '@material-ui/icons';
import clsx from 'clsx';

const useStyles = makeStyles((theme) => ({
    root: {
        padding: '2px 2px',
        paddingLeft: theme.spacing(2),
        display: 'flex',
        alignItems: 'center',
        borderRadius: 40,
        margin: theme.spacing(1),
        background: "#373737",
    },
    input: {
        marginLeft: theme.spacing(1),
        flex: 1,
    },
    iconButton: {
        padding: 10,
    }
}));

export default function SearchBar(props) {
    const classes = useStyles();
    const history = useHistory();

    const [value, setValue] = useState("");

    const handleSubmit = () => {
        if(value.length == 0) return;
        
        history.push(`/search/${encodeURIComponent(value)}`);
    };

    return (
        <Paper component="form" className={clsx(classes.root, props.className)} onSubmit={(e) => {e.preventDefault(); handleSubmit()}}>
        <InputBase
            className={classes.input}
            placeholder="Išči po pesmarici"
            onChange={(e) => setValue(e.target.value)}
            value={value}
        />
        <IconButton className={classes.iconButton} onClick={handleSubmit}>
            <SearchIcon />
        </IconButton>
        </Paper>
    );
}