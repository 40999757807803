import React, {useState} from "react";
import { makeStyles, useTheme } from '@material-ui/core/styles';
import { Typography, Button } from "@material-ui/core";
import { useEffect } from "react";

const useStyles = makeStyles((theme) => ({
	container: {
        margin: theme.spacing(4, 2, 2),
		backgroundColor: theme.palette.secondary.dark,
		borderRadius: theme.spacing(1),
		padding: theme.spacing(2),
		[theme.breakpoints.up('lg')]: {
			position: "fixed",
			bottom: 56,
			right: 0,
			zIndex: theme.zIndex.appBar+100,
			maxWidth: 400,
		}
	},
	title: {
		margin: 0,
	}
}));

export default function InstallPrompt(props){
	const classes = useStyles();

	const installPrompt = props.installPrompt;

	function handleInstall(){
		if(!installPrompt) return;

		installPrompt.prompt();
		installPrompt.userChoice.then((choiceResult) => {
			if (choiceResult.outcome === "accepted") {
				console.log("User accepted the A2HS prompt");
			} else {
				console.log("User dismissed the A2HS prompt");
			}
			setShow(false);
		});
	}

	if(installPrompt){
		return <div className={classes.container}>
			<Typography variant="body1">Aplikacijo Jubilate lahko namestite in jo uporabljate tudi brez povezave.</Typography>
			<div style={{textAlign: "right"}}>
				<Button variant="outlined" color="default" onClick={handleInstall}>
					Namesti
				</Button>	
			</div>
		</div>
	}
	else{
		return null;
	}
}