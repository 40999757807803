import React, { useState } from 'react';
import { useHistory } from "react-router-dom";
import { 
	IconButton, 
	Typography, 
	Menu, 
	MenuItem, 
	ListItemIcon, 
	Button, 
	TextField, 
	Dialog, 
	DialogActions, 
	DialogContent, 
	DialogContentText, 
	DialogTitle,
	makeStyles,
} from '@material-ui/core';
import { Add, Delete, Edit, MoreVert, Share } from '@material-ui/icons';
import LocalStorage from 'lib/localstorage';
import { getPlaylistCode, removePlaylist } from 'lib/library';
import ShareDialog from './ShareDialog';

const useStyles = makeStyles((theme) => ({
    root: {
      
    },
    title: {
        flexGrow: 1,
    },
    listIcon: {
        minWidth: 32,
    },
	toolbarContainer: {
		width: '100%',
		position: 'fixed',
		//border: '4px solid hotpink',
		boxSizing: 'border-box',
		zIndex: 50,
		padding: theme.spacing(0, 1),
		background: 'rgba(0,0,0,0.6)',
		backdropFilter: 'blur(5px)',
		left: -8,
	},
	toolbar: {
		position: 'relative',
		left: 8,
		minHeight: 50,
		paddingLeft: 500 + theme.spacing(7),
		paddingTop: 16,
		paddingBottom: 4,
		paddingRight: theme.spacing(3),
		display: 'flex',
		justifyContent: 'space-between',
		alignItems: 'center',
		[theme.breakpoints.down('md')]: {
			paddingLeft: theme.spacing(0),
			paddingTop: theme.spacing(1),
			paddingRight: theme.spacing(0),
		},
	}
}));

export default function MylistMenu(props){
	const classes = useStyles();
	const history = useHistory();
	const local = new LocalStorage();

    const [renameListPopupOpened, setRenameListPopupOpened] = React.useState(false);
    const [shareListPopupOpened, setShareListPopupOpened] = React.useState(false);
    const [renameListInput, setRenameListInput] = useState(props.list.title);
	const [shareURL, setShareURL] = useState('');


	const [anchorEl, setAnchorEl ] = useState(null);

    const open = Boolean(anchorEl);

	const handleMenu = (event) => {
        setAnchorEl(event.currentTarget);
    }

    const handleClose = () => {
        setAnchorEl(null);
    };

	const deleteList = () => {
		handleClose();

		removePlaylist(Number(props.list.key));
		
		history.push("/profile");
	}

	const handleRenameList = () => {
		props.handleRename(renameListInput);
		setRenameListPopupOpened(false);
	}

	const handleShare = () => {
		handleClose();
		setShareListPopupOpened(true);
		setShareURL(
			`${window.location.origin}/list/${encodeURIComponent(props.list.title)}/${getPlaylistCode(props.list)}`
		);
	}

	return <>
		<IconButton onClick={handleMenu}><MoreVert/></IconButton>
		<Menu
			id="menu-appbar"
			anchorEl={anchorEl}
			anchorOrigin={{
			vertical: 'top',
			horizontal: 'right',
			}}
			keepMounted
			transformOrigin={{
			vertical: 'top',
			horizontal: 'right',
			}}
			open={open}
			onClose={handleClose}
		>
			<MenuItem onClick={()=>{handleClose(); setRenameListPopupOpened(true)}}>
				<ListItemIcon className={classes.listIcon}>
					<Edit fontSize="small" />
				</ListItemIcon>
				<Typography variant="inherit">Preimenuj</Typography>
			</MenuItem>
			<MenuItem onClick={()=>{deleteList()}}>
				<ListItemIcon className={classes.listIcon}>
					<Delete fontSize="small" />
				</ListItemIcon>
				<Typography variant="inherit">Odstrani</Typography>
			</MenuItem>
			<MenuItem onClick={handleShare}>
				<ListItemIcon className={classes.listIcon}>
					<Share fontSize="small" />
				</ListItemIcon>
				<Typography variant="inherit">Deli</Typography>
			</MenuItem>
		</Menu>

		<Dialog open={renameListPopupOpened} onClose={()=>setRenameListPopupOpened(false) } aria-labelledby="form-dialog-title">
			<DialogTitle id="form-dialog-title">Nov seznam</DialogTitle>
			<DialogContent>
				<DialogContentText>
					Izberi ime seznama, na katerega lahko shraniš pesmi in jih deliš z ostalimi.
				</DialogContentText>
				<TextField
					autoFocus
					margin="dense"
					id="name"
					label="Ime seznama"
					type="text"
					fullWidth
					value={renameListInput}
					onChange={e=>setRenameListInput(e.target.value)}
				/>
				</DialogContent>
				<DialogActions>
				<Button onClick={()=>{setRenameListPopupOpened(false); setRenameListInput(props.list.title)}} color="primary">
					Prekliči
				</Button>
				<Button onClick={handleRenameList} color="primary">
					Preimenuj
				</Button>
			</DialogActions>
		</Dialog>
		<ShareDialog opened={shareListPopupOpened} 
			title="Deli seznam" 
			text="Kopiraj povezavo za deljenje seznama:" 
			url={shareURL}
			close={() => setShareListPopupOpened(false)} 
		/>
	</>
}