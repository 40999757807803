import React from 'react';
import { Typography } from '@material-ui/core';
import { makeStyles } from '@material-ui/core/styles';
import CategoryList from 'components/category/CategoryList.js';
import Max from 'components/util/Max.js';
import ScrollArea from 'components/util/ScrollArea.js';

const useStyles = makeStyles((theme) => ({
	root: {
		margin: theme.spacing(4),
		[theme.breakpoints.down('md')]: {
			margin: theme.spacing(2),
		},
	},
}));
  
export default function Categories(){
	const classes = useStyles();

	return (
		<ScrollArea>
		<Max compensateScrollbar>
			<div className={classes.root}>
			<Typography variant="h1">Kategorije</Typography>
			<CategoryList></CategoryList>
			</div>
		</Max>
		</ScrollArea>
	);
}