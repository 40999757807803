import React, { Profiler, useEffect, useState } from 'react';

import { Typography, AppBar, Toolbar, Grid, IconButton, MenuItem, Menu, ListItemIcon, Divider, FormControl, FormGroup, FormControlLabel, Checkbox, Dialog, DialogActions, DialogContent, DialogTitle, Button, makeStyles, TextField, InputAdornment, InputLabel, OutlinedInput } from '@material-ui/core';
import { ArrowBack, Favorite, MoreVert, PlaylistAdd, FavoriteBorder, Share, VisibilityOff, WrapText, Fullscreen, FormatSize, Visibility, Add } from '@material-ui/icons';

import Max from '../util/Max';
import { addPlaylist, addSongToPlaylist, getPlaylists, getPlaylistsFull } from 'lib/library';

export default function AddToListDialog(props){
	if(!props.song) return null;

	const [lists, setLists] = useState([]);
	const [selectedLists, setSelectedLists] = useState({});
	const [newListName, setNewListName] = useState("");

	// naloži sezname
	useEffect( () => {
		setLists(getPlaylists());
	}, []);


	const handleListSelect = (event) => {
        setSelectedLists({ ...selectedLists, [event.target.name]: event.target.checked });
    };

	const handleConfirm = () => {
		for(let id in selectedLists){
			if(selectedLists[id]) addSongToPlaylist(id, props.song.id);
		}

		setSelectedLists({});
		props.onClose();
	}

	const handleAddList = () => {
		if(newListName == "")return;
        addPlaylist(newListName)
        setLists(getPlaylistsFull());
		setNewListName("");
	}

	return <Dialog open={props.open} onClose={props.onClose} fullWidth={true} maxWidth="xs">
		<DialogTitle id="form-dialog-title">Dodaj na seznam</DialogTitle>
		<DialogContent dividers>
			<FormControl component="fieldset">
				<FormGroup>
					{lists ? lists.map((list, key) => 
						<FormControlLabel
							control={<Checkbox checked={selectedLists[key+""] ? true : false} onChange={handleListSelect} name={key+""} />}
							label={list.title} key={key}
						/>
					) : ""}
				</FormGroup>
			</FormControl>
			<FormControl variant="outlined" fullWidth margin="normal" component="form" onSubmit={e => {e.preventDefault(); handleAddList()}}>
				<InputLabel htmlFor="new-list">Nov seznam</InputLabel>
				<OutlinedInput id="new-list" type='text' onChange={e => setNewListName(e.target.value)} value={newListName} endAdornment={
					<InputAdornment position="end">
						<IconButton edge="end" onClick={handleAddList}>
							<Add />
						</IconButton>
					</InputAdornment>
					}
					label="Nov seznam"
				/>
			</FormControl>
		</DialogContent>
		<DialogActions>
			<Button onClick={() => {props.onClose(); setSelectedLists({})}} color="primary">
					Prekliči
			</Button>
			<Button onClick={handleConfirm} color="primary">
					Dodaj
			</Button>
		</DialogActions>
	</Dialog>
}