import React, { useEffect } from 'react';
import { makeStyles } from '@material-ui/core/styles';
import Typography from '@material-ui/core/Typography';
import { transposeGroup } from 'lib/library';
import transitions from '@material-ui/core/styles/transitions';

const useStyles = makeStyles((theme) => ({
    root: {
        width: '100%',
        position: 'relative',
        left: -4,
        overflowX: 'auto',
    },
    container: {
        paddingTop: theme.spacing(2),
        paddingRight: theme.spacing(3),
        paddingLeft: theme.spacing(3),
        //whiteSpace: "nowrap",
        boxSizing: 'border-box',
        display: 'inline-block',
        //background: 'tomato',
    },
    segment:{
        paddingBottom: "2em",
        display: 'inline-block',
        fontWeight: 300,
    },
    chorus: {
        fontWeight: 500
    },
    chorusLabel: {
        fontWeight: 500,
    },
    bridge: {
        fontStyle: "italic",
    },
    repeatLabel: {
        color: theme.palette.primary.main,
        fontSize: "0.8em",
        fontWeight: "bold"
    },
    label: {
        color: theme.palette.grey[500],
        fontSize: "0.8em"
    },
    chord: {
        fontSize: "0.8em",
        fontStyle: "normal",
        fontWeight: "bold",
        padding: "0.1em 0.2em",
        borderRadius: "3px",
        lineHeight: "1em",
        background: theme.palette.secondary.light,
        color: "black",
        display: "inline-block",
        whiteSpace: "nowrap",
        marginRight: 1,
        "& > *": {
            fontSize: "0.8em",
            fontStyle: "normal",
            fontWeight: "bold",
            background: theme.palette.secondary.light,
            color: "black",
        }
    },
    chordAnchor: {
        position: "relative",
        paddingTop: "2em",
        display: "inline-block",
        borderBottom: "1px solid #666",
        bottom: "0.2em",
        "& > *": {
            position: "absolute",
            top: "0.3em",
        }
    },
    antiBreak: {
        whiteSpace: 'nowrap',
    },
  }));
  
  export default function Lyrics(props){
      const classes = useStyles();
      const lyricsRoot = React.createRef();


    // dodaj razmake v besedilu kjer se akordi prekrivajo
    useEffect((e) => {
        //const chords = lyricsRoot.current.getElementsByClassName(classes.chord);
        const paddingElements = lyricsRoot.current.getElementsByClassName(classes.chordAnchor);
        for(let i=1; i<paddingElements.length; i++){
            paddingElements[i].style.paddingLeft = 0;
        }
        for(let i=1; i<paddingElements.length; i++){
            let chord = paddingElements[i].getElementsByClassName(classes.chord)[0];
            let chordPrev = paddingElements[i-1].getElementsByClassName(classes.chord)[0];
            let rect = chord.getBoundingClientRect();
            let rectPrev = chordPrev.getBoundingClientRect();
            let padding = 0;
            if(Math.abs(rectPrev.top - rect.top) < 1) padding = rectPrev.right - rect.left + 1;
            padding = padding < 0 ? 0 : padding;
            paddingElements[i].style.paddingLeft = padding+"px";
        }

    })

    let transposeSemitones = props.transposeSemitones || 0;
    let text = props.text;
    let segments = text.split(/\n\n+/g);
    const inlineChordStart = `<span class="${classes.chord}">`;
    const inlineChordEnd = `</span>`;
    const chordStart = `<span class="${classes.chordAnchor}">${inlineChordStart}`;
    const chordEnd = `</span>${inlineChordEnd}`;
    segments = segments.map(s => 
        `<div class="${classes.segment} ${s.substr(0,1) == "*" ? classes.chorus : ""}">` + 
            s
            .replace(/\{(.*?)\}/g, m => `${m.replace(/ /g, ';')}` ) // v skupinah akordov zamenjaj presledke z ; 
            .replace(/\*(.*)$/igm, `$1`) // pobriši * na začetku vrstice
            .replace(/\/ (.*?) \//sg, `__bridge_start__$1__bridge_end__`) // dodaj span okoli bridge (označen z /.../)
            .split(/\n/g).map( vrstica => vrstica.split(' ').map(w => w == '/' ? w : ( w.indexOf('{') == -1 ? w : `<span class="${classes.antiBreak}">${w}</span>`)).join(" ")).join('\n') // dodaj anti-break sapn okoli besed z akordi
            .replace(/__bridge_start__(.*?)__bridge_end__/sg, `<i class=${classes.bridge}>$1</i>`) // dodaj span okoli bridge (označen z /.../)
            .replace(/\{\{(.*?)\}\}/g, 
                (m, n) => 
                    props.showChords 
                    ?   `${inlineChordStart}${transposeGroup(n, transposeSemitones).replace(/ /g, `${inlineChordEnd}${inlineChordStart}`)}${inlineChordEnd}` 
                    : ""
            ) // označi akorde v liniji (v zavitih oklepajih {{...}})
            .replace(/\{(.*?)\}/g, 
                (m) => props.showChords 
                ? `${chordStart}${m.replace(/;/g, `${chordEnd}${chordStart}`).replace(/\{(.*?)\}/g, (m,n) => transposeGroup(n, transposeSemitones))}${chordEnd}` 
                : ""
            ) // označi akorde (v zavitih oklepajih {...})
            .replace(/\n/g, `<br>`) // dodaj <br> med vrstice
            .replace(/( Odp\.)/gi, `<span class="${classes.chorusLabel}">$1</span>`) // označi Odp.
            .replace(/\((\d*?x)\)/g, `<span class="${classes.repeatLabel}">($1)</span>`) // označi ponavljanje (...x)
            .replace(/\[(.*?)\]/g, `<span class="${classes.label}">[$1]</span>`) // označi stvari v oglatih oklepajih [...]
        + "</div><br>"
    );
    let html = segments.join("\n");

    return <div className={classes.root}>
        <div className={classes.container} ref={lyricsRoot} style={{fontSize: props.fontSize, fontFamily: props.fontFamily, whiteSpace: props.wrap ? 'normal' : 'nowrap'}} dangerouslySetInnerHTML={{__html: html}}></div>
    </div>
}