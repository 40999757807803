import React, { useEffect, useRef, useState } from 'react';
import { Link, matchPath, useLocation } from "react-router-dom";
import { useMediaQuery, useTheme, CssBaseline } from '@material-ui/core';
import { makeStyles } from '@material-ui/core/styles';
import Navigation from './Navigation.js';

const useStyles = makeStyles((theme) => ({
    root: {
        height: '100%',
        width: '100%',
        paddingBottom: 56,
        paddingTop: 0,
        [theme.breakpoints.up('lg')]: {
            paddingTop: 70,
            paddingBottom: 0,
        }
    },
    main: {
        position: 'relative',
        width: '100%',
        maxWidth: '100%',
        height: '100%',
        //border: '3px hotpink solid',
        //background: 'pink',
        overflowY: 'scroll',
        overflowX: 'hidden',
        boxSizing: 'border-box',
    },
    searchBar: {
        position: "fixed",
        zIndex: theme.zIndex.appBar,
        width: "100%",
        [theme.breakpoints.up('lg')]: {
            width: "300px",
            marginLeft: theme.spacing(2),
            marginTop: -67,
        }
    },
}));
  
export default function Scaffold(props){
    const location = useLocation();
    const classes = useStyles();
    const theme = useTheme();
    const mobile = !useMediaQuery(theme.breakpoints.up('lg'), true);

    let showSearch = true;
    if(mobile){
        if(matchPath(location.pathname, {path: "", exact: true})) showSearch = true;
        else showSearch = false;
    }

    // skrivanje navigacije ko je odprta tipkovnica - TODO
    /*React.useEffect(() => {
        // hide bottom navigation when keyboard is present
        function handleResize() {
            //console.log('resized to: ', window.innerWidth, 'x', window.innerHeight, document.activeElement.tagName, document.getElementById("jubilate-main-nav"))

            if(document.activeElement.tagName == "INPUT"){
                document.getElementById("jubilate-main-nav").style.display = "none";
            }
            else{
                document.getElementById("jubilate-main-nav").style.display = "block";
            }
        }

        window.addEventListener('resize', handleResize)
    }, [])*/

    return (
        <CssBaseline>
            <div className={classes.root} >
                <Navigation></Navigation>
                {props.children}    
            </div>
        </CssBaseline>
    );
}