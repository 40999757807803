import React from 'react';
import clsx from 'clsx';
import { makeStyles } from '@material-ui/core/styles';

import { sortableContainer, sortableElement } from 'react-sortable-hoc';
import SongCard from './SongCard';

const useStyles = makeStyles((theme) => ({
    root: {
        width: '100%',
        padding: theme.spacing(1, 0),
    },
    rootGrid: {
        display: 'grid',
        gridTemplateColumns: 'repeat(4, 1fr)',
        [theme.breakpoints.down('md')]: {
            gridTemplateColumns: 'repeat(3, 1fr)',
        },
        [theme.breakpoints.down('sm')]: {
            gridTemplateColumns: 'repeat(2, 1fr)',
        },
        [theme.breakpoints.down('xs')]: {
            gridTemplateColumns: 'repeat(1, 1fr)',
        },
    },
    dragged: {
        zIndex: 1000,
        '&>*': {
            background: theme.palette.background.paper,
            [theme.breakpoints.down('md')]: {
                background: theme.palette.background.default,
            },
        }
    },
}));

const SortableContainer = sortableContainer(({children, className}) => {
    return <div className={className}>{children}</div>;
});

export default function SongList(props){
    const classes = useStyles();

    const songs = props.songs;
    if(!songs)return null;


    if(props.onSort) return (
        <SortableContainer className={clsx(classes.root, props.grid && classes.rootGrid)} onSortEnd={props.onSort} useDragHandle lockAxis='y' helperClass={classes.dragged}>
            {songs.map((song, key) => song && (
                <SongCard sortable removeHandle={() => props.onRemove(key)} key={`item-${song.id}-${key}`} i={key} index={key} song={song} linkState={props.linkState} active={key === props.activeSong}/>
            ))}
        </SortableContainer>
    );
    else return (
        <div className={clsx(classes.root, props.grid && classes.rootGrid)}>
            {songs.map((song, key) => song && (
                <SongCard key={`item-${song.id}-${key}`} i={key} index={key} song={song} linkState={props.linkState} active={key === props.activeSong}/>
            ))}
        </div>
    );
}