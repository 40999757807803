import React, { useState, useEffect } from 'react';
import { Link, useHistory } from "react-router-dom";
import { useMediaQuery, Typography, Button, Card } from '@material-ui/core';
import { makeStyles, useTheme } from '@material-ui/core/styles';
import { ArrowForward } from '@material-ui/icons';
import Grid from 'components/util/Grid';
import CategoryCard from 'components/category/CategoryCard';
import PlaylistCard from 'components/playlist/PlaylistCard';
import Max from 'components/util/Max.js';
import ScrollArea from 'components/util/ScrollArea';
import SearchBar from 'components/search/SearchBar.js';
import HideOnScroll from 'components/util/HideOnScroll.js';
import { getRandomSongId, useRecentSongs, getRecentCategories, getPlaylistsFull, getFavouritesList } from 'lib/library';
import SongList from 'components/song/SongList';
import InstallPrompt from 'components/util/InstallPrompt';
import SongWheel from './SongWheel';
import AdBanner from 'components/util/AdBanner';


const useStyles = makeStyles((theme) => ({
    padding: {
        paddingLeft: theme.spacing(1),
        paddingRight: theme.spacing(1),
    },
    spacer: {
        paddingTop: theme.spacing(4),
    },
    container: {
        width: "100%",
        padding: theme.spacing(0, 2, 2),
        boxSizing: "border-box",
        [theme.breakpoints.up('lg')]: {
            padding: theme.spacing(0, 4, 4),
        },
    },
    titleRow: {
        display: "flex",
        justifyContent: "space-between",
        alignItems: "baseline",
        margin: theme.spacing(0, 2),
        [theme.breakpoints.down('sm')]: {
            flexDirection: "column",
        },
        [theme.breakpoints.up('lg')]: {
            margin: theme.spacing(0, 4),
        },
    },
    title: {
        [theme.breakpoints.down('sm')]: {
            marginBottom: theme.spacing(2),
        },
    },
    moreButton: {
        [theme.breakpoints.down('sm')]: {
            alignSelf: "flex-end",
            marginBottom: theme.spacing(1),
        },
    },
    searchBar: {
        position: "fixed",
        zIndex: theme.zIndex.appBar,
        width: "100%",
        left: 0,
        padding: theme.spacing(1),
        boxSizing: 'border-box',
        [theme.breakpoints.up('lg')]: {
            display: 'none',
        }
    },
    topPadding: {
        [theme.breakpoints.down('md')]: {
            paddingTop: 60,
        },
    },
    emptySection: {
        display: 'flex',
        justifyContent: 'space-between',
        alignItems: 'center',
        flexDirection: 'column',
        height: 120,
        padding: theme.spacing(3),
    },
  }));

export default function Home(props){
    const classes = useStyles();
    const theme = useTheme();
    const history = useHistory();
    const mobile = !useMediaQuery(theme.breakpoints.up('lg'), true);

    const recentSongs = useRecentSongs(12);

    const [scrollTarget, setScrollTarget] = useState(undefined);
    const [recentCategories, setRecentCategories] = useState([]);
    const [lists, setLists] = useState([]);
    const [favourites, setFavourites] = useState({});


    useEffect(() => {
        setRecentCategories(getRecentCategories(6));
        setLists(getPlaylistsFull());
        setFavourites(getFavouritesList());
    }, []);
    
    return <>
        <ScrollArea className={classes.main} ref={el => el && setScrollTarget(el)} >
		<Max compensateScrollbar>
        <HideOnScroll window={scrollTarget}>
            <div className={classes.searchBar}>
                <SearchBar handleSubmit={() => {}}></SearchBar>
            </div>
        </HideOnScroll>


        <div className={classes.topPadding}></div>
        <InstallPrompt installPrompt={props.installPrompt} />
        <div className={classes.titleRow}>
            <Typography variant="h1" className={classes.title}>Kategorije</Typography>
            <Button color="secondary" variant="text" endIcon={<ArrowForward/>} component={Link} to="/categories" className={classes.moreButton}>Vse kategorije</Button>
        </div>
        <div className={classes.container}>
            <Grid>
                { recentCategories.map(category => 
                    <CategoryCard key={category.id || "all"} category={category}></CategoryCard>
                ) }
            </Grid>
        </div>
        <div className={classes.titleRow}>
            <Typography variant="h1" className={classes.title}>Nedavno obiskane pesmi</Typography>
            <Button color="secondary" variant="text" endIcon={<ArrowForward/>} component={Link} to="/history" className={classes.moreButton}>Zgodovina</Button>
        </div>
        <div className={classes.container}>
            { recentSongs.songs?.length
                ?   <SongList songs={recentSongs.songs} grid linkState={{type: "history", key: ""}}></SongList>
                :   <section className={classes.emptySection}>
                        <Typography variant="body2">Nedavno obiskanih pesmi še ni.</Typography>
                        <Button color="secondary" variant="contained" onClick={e => history.push(`/song/${getRandomSongId()}`)}>Naključna pesem</Button>
                    </section>
            }
        </div>
        <div className={classes.titleRow}>
            <Typography variant="h1" className={classes.title}>Moji seznami</Typography>
            <Button color="secondary" variant="text" endIcon={<ArrowForward/>} component={Link} to="/profile" className={classes.moreButton}>Vsi seznami</Button>
        </div>
        <div className={classes.container}>
            <Grid>
                <PlaylistCard playlist={favourites}></PlaylistCard>
                { lists.splice(0,5).map( (list, i) => 
                    <PlaylistCard key={i} playlist={list} />    
                )}
            </Grid>
        </div>
        
        
        <div className={classes.spacer}></div>
        <div className={classes.container}>
            <SongWheel></SongWheel>
        </div>

        <div className={classes.container}>
            <AdBanner/>
        </div>

        </Max>
        </ScrollArea>
    </>
}