import React, { useState } from 'react';
import clsx from 'clsx';

import { Typography, Chip, Avatar } from '@material-ui/core';
import { PlayCircleOutline, ExpandLess, ExpandMore } from '@material-ui/icons';
import { makeStyles } from '@material-ui/core/styles';

import Lyrics from 'components/song/Lyrics';
import YoutubePlayer from 'components/util/YoutubePlayer';
import { transpose } from 'lib/library';
import { useEffect } from 'react';


const useStyles = makeStyles((theme) => ({
    tags: {
        display: 'flex',
        justifyContent: 'left',
        flexWrap: 'wrap',
        margin: '10px 0',
        paddingLeft: 4,
        paddingRight: 4,
        paddingBottom: theme.spacing(2),
        '& > *': {
        margin: theme.spacing(0.5),
        },
    },
    heading: {
        paddingLeft: theme.spacing(2),
        paddingRight: theme.spacing(2),
    },
    video: {
        overflow: 'hidden',
        margin: theme.spacing(2, 0),
        [theme.breakpoints.up('lg')]: {
            borderRadius: theme.spacing(3),
            background: theme.palette.grey[900],
            border: `4px solid ${theme.palette.grey[900]}`,
            position: 'sticky',
            maxWidth: 400,
            maxHeight: 310,
            //height: 'auto',
            bottom: theme.spacing(2),
            right: theme.spacing(2),
            margin: theme.spacing(2, 0, 2, 'auto'),
            transition: 'max-height 0.3s',
            boxShadow: '0 0 10px 0 rgba(0, 0, 0, 0.5)',
        }
    },
    videoHidden: {
        [theme.breakpoints.up('lg')]: {
            maxHeight: 62,
        }
    },
    videoRow: {
        display: 'none',
        [theme.breakpoints.up('lg')]: {
            display: 'flex',
            alignItems: 'center',
            padding: theme.spacing(2),
        }
    },
    showVideoIcon: {
        transition: 'transform 0.4s',
    }
}));

export default function SongView(props){
    const classes = useStyles();
    const [showVideo, setShowVideo] = useState(true);

    const [isOnLine, setIsOnLine] = useState(false);

    useEffect(() => {
        setIsOnLine(navigator.onLine !== false);

        // request wake lock
        if (navigator.wakeLock) {
            (async () => {
                try {
                    const wakeLock = await navigator.wakeLock.request('screen');
                    wakeLock.addEventListener('release', () => {
                        console.log('Wake Lock was released');
                    });
                    console.log('Wake Lock is active');
                } catch (err) {
                    console.error(`${err.name}, ${err.message}`);
                }
            })();
        }
    }, []);


    const song = props.song;
    if(!song)return <></>;
    let key = 0;
    return <>
        <Typography className={classes.heading} variant="h1" style={{marginBottom: 8, marginTop: 0, paddingTop: 16}}>{song.title}</Typography>
        <Typography className={classes.heading} variant="subtitle1" color="textSecondary">{song.author}</Typography>
        <Typography className={classes.heading} variant="caption" color="primary">{song.music_key} {props.transposeSemitones != 0 ? "(transponirano v " + transpose(song.music_key, props.transposeSemitones)+ ")" : ""}  {song.music_key && song.rhythm ? <>&#8231;</> : ""} {song.rhythm}</Typography>
        <div className={classes.tags}>
            { song.page ? <Chip key={key++} avatar={<Avatar>{song.page}</Avatar>} label="Jubilate 3" color="primary"/> : "" }
            { song.tags ? 
                song.tags.split(",").filter(v => v.trim()).map((v) => <Chip label={v.trim()} key={key++} color="secondary"/>) 
                : ""
            }
            {song.categories ? 
                song.categories.split(",").filter(v => v.trim()).map((v) => <Chip label={v.trim()} key={key++} color="primary"/>)
                : ""
            }
        </div>
        <Lyrics showChords={props.showChords} fontSize={props.fontSize} fontFamily={props.fontFamily} text={song.lyrics} wrap={props.lineWrap} transposeSemitones={props.transposeSemitones}/>
        { (song.video && !props.fullScreen && isOnLine) &&
            <div className={clsx(classes.video, showVideo ? '' : classes.videoHidden)}>
                <div className={classes.videoRow} onClick={() => setShowVideo(!showVideo)}>
                    <PlayCircleOutline/>
                    <Typography variant="h4" style={{paddingLeft: 8, margin: 0}}>Video</Typography>
                    <div style={{width:'100%'}}></div>
                    <ExpandMore className={classes.showVideoIcon} style={{transform: showVideo ? 'rotate(0deg)' : 'rotate(180deg)'}}/>
                </div>
                <YoutubePlayer url={song.video}></YoutubePlayer>
            </div>
        }
    </>
}