class LocalStorage{
    constructor(){
        this.data;
        try{
            this.data = JSON.parse(localStorage.jubilate);
        }
        catch(e){}

        if(!this.data)this.data = {}

        if(!this.data.playlists)this.data.playlists = []
        if(!this.data.favourites)this.data.favourites = []
        if(!this.data.settings)this.data.settings = {}
    }

    save(){
        localStorage.jubilate = JSON.stringify(this.data);
    }

}

export default LocalStorage