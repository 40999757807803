import React from "react";
import { Box, Card, makeStyles, SvgIcon, Typography } from "@material-ui/core";
import { Link } from "react-router-dom";
import Advent from "icons/advent.svg";
import Aleluja from "icons/aleluja.svg";
import Bozic from "icons/bozic.svg";
import Darovanjske from "icons/darovanjske.svg";
import GospodUsmiliSe from "icons/gospod-usmili-se.svg";
import JagnjeBozje from "icons/jagnje-bozje.svg";
import Jutranje from "icons/jutranje.svg";
import Ljudske from "icons/ljudske.svg";
import Marija from "icons/marija.svg";
import Obhajilne from "icons/obhajilne.svg";
import Otroske from "icons/otroske.svg";
import Post from "icons/post.svg";
import Psalmi from "icons/psalmi.svg";
import Sklepne from "icons/sklepne.svg";
import Slava from "icons/slava.svg";
import Slavilne from "icons/slavilne.svg";
import Spokorne from "icons/spokorne.svg";
import Svet from "icons/svet.svg";
import SvetiDuh from "icons/sveti-duh.svg";
import Svetniki from "icons/svetniki.svg";
import Tajzejske from "icons/tajzejske.svg";
import Vecerne from "icons/vecerne.svg";
import VelikaNoc from "icons/velika-noc.svg";
import Vse from "icons/vse.svg";
import Vstopne from "icons/vstopne.svg";

const useStyles = makeStyles((theme) => ({
	root: {
		//maxWidth: 200,
		backgroundColor: theme.palette.secondary.main + " !important",
		paddingTop: theme.spacing(2),
		paddingBottom: theme.spacing(2),
		whiteSpace: "nowrap",
		textDecoration: "none",
		'&>*': {
			marginRight: theme.spacing(2),
			marginLeft: theme.spacing(2),
		}
	},
	title: {
		fontWeight: "500",
		fontSize: "2em",
		color: "#6e340b",
		margin: theme.spacing(1, 2, 0),
		lineHeight: "1.2em",
		[theme.breakpoints.down('md')]: {
			fontSize: "1.3em",
		},
	},
	subtitle: {
		color: "#9e541f",
		margin: theme.spacing(0, 2, 0) + " !important",
	}
}));

export default function CategoryCard(props){
	const classes = useStyles();
	let category = props.category;
	if(!category){
		category = {id: 0, title: "Placeholder", count: 69};
	}

	let icon = null;
	switch(category.title){
		case "Advent": icon = Advent; break;
		case "Aleluja": icon = Aleluja; break;
		case "Božič": icon = Bozic; break;
		case "Darovanjske": icon = Darovanjske; break;
		case "Gospod usmili se": icon = GospodUsmiliSe; break;
		case "Jagnje božje": icon = JagnjeBozje; break;
		case "Jutranje": icon = Jutranje; break;
		case "Ljudske": icon = Ljudske; break;
		case "Marija": icon = Marija; break;
		case "Obhajilne": icon = Obhajilne; break;
		case "Otroške": icon = Otroske; break;
		case "Post": icon = Post; break;
		case "Psalmi": icon = Psalmi; break;
		case "Sklepne mašne": icon = Sklepne; break;
		case "Slava": icon = Slava; break;
		case "Slavilne": icon = Slavilne; break;
		case "Spokorne": icon = Spokorne; break;
		case "Svet": icon = Svet; break;
		case "Sveti duh": icon = SvetiDuh; break;
		case "Svetniki": icon = Svetniki; break;
		case "Taizejske": icon = Tajzejske; break;
		case "Večerne": icon = Vecerne; break;
		case "Velika noč": icon = VelikaNoc; break;
		case "Vse pesmi": icon = Vse; break;
		case "Vstopne": icon = Vstopne; break;
	}

	return <Card component={Link} to={"/category/"+(category.id || "all")} className={classes.root} color="primary">
		<img src={icon} style={{width: 42}} />
		<Box className={classes.title} component="div" textOverflow="ellipsis" overflow="hidden" >
			{category.title}
		</Box>
		<Typography className={classes.subtitle}>{category.count} pesmi</Typography>
	</Card>
}