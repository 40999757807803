import React from 'react';
import { hydrate } from 'react-dom';
import theme from './theme';
import { ThemeProvider } from '@material-ui/styles';
import { BrowserRouter } from 'react-router-dom';
import App from './App';

import * as OfflinePluginRuntime from "offline-plugin/runtime";
OfflinePluginRuntime.install();

hydrate(
  <ThemeProvider theme={theme}>
    <BrowserRouter>
      <App />
    </BrowserRouter>
  </ThemeProvider>,
  document.getElementById('root'),
  () => {
    // [ReHydratation](https://github.com/cssinjs/jss/blob/master/docs/ssr.md)
    const jssStyles = document.getElementById('jss-ssr');
    if (jssStyles && jssStyles.parentNode) {
      // jssStyles.parentNode.removeChild(jssStyles); // zaradi tega css ni delal v prod
    }
  }
);

if (module.hot) {
  module.hot.accept();
}